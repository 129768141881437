<script>
import JobSearchForm from 'src/components/JobSearchForm.vue'
import FavoriteSearches from 'src/components/FavoriteSearches.vue'
import FeaturedJobotPros from './FeaturedJobotPros.vue'
import ParallaxBackground from 'src/components/ParallaxBackground.vue'

import { meta } from 'src/lib/util.js'
import { ref, computed } from 'vue'
import { useStore } from 'stores/api'
import { useQuasar } from 'quasar'

export default {
  components: { ParallaxBackground, JobSearchForm, FavoriteSearches, FeaturedJobotPros },
  setup () {
    const quasar = useQuasar()
    const store = useStore()
    const home = computed(() => store.state.api['/rest/home'])
    const slide = ref(1)
    return {
      home,
      slide,
      quasar
    }
  },
  async preFetch () {
    const store = useStore()
    await store.dispatch('api/get', '/rest/home')
  },
  mixins: [
    meta(x => x.home?.seo)
  ]
}
</script>
<template>
  <div class="HomePage">
    <q-parallax :height="quasar.screen.lt.md ? 300 : 500" :speed="0.7">
      <template #media>
        <ParallaxBackground :image="home?.banner" class="whiteout" height="170%" />
      </template>
      <template #content>
        <div class="container q-px-sm">
          <h3 class="gt-xs">
            <strong>{{ home?.title || 'Hire or Be Hired.' }}</strong>
            <br>
            <span class="subtext">{{ home?.caption || 'Your Jobot AI powered job search starts here.' }}</span>
          </h3>
          <JobSearchForm restore />
        </div>
      </template>
    </q-parallax>

    <div class="container">
      <FavoriteSearches class="q-mt-md" />
    </div>

    <div class="container q-mt-lg">
      <div class="row q-col-gutter-xl q-mb-lg">
        <div class="col-12">
          <h2 class="text-h5 q-ma-none q-pa-none text-weight-medium full-width text-center">
            Featured Jobot Pros
          </h2>
        </div>
        <div class="col-12 text-center">
          <div class="q-pa-md">
            <FeaturedJobotPros />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>

  .whiteout {
    filter: opacity(15%);
    border-top: solid 100px transparent;
  }
  h3 {
    font-size: 26px;
  }

  @media (max-width: $breakpoint-xs-max) {
    .hero {
      height: 100px;
    }
  }
  @media (min-width: $breakpoint-sm-min) and (max-width: $breakpoint-sm-max) {
    .hero {
      height: 200px;
    }
  }
  @media (min-width: $breakpoint-md-min) {
    .hero {
      height: 300px;
    }
  }

  @media (max-width: $breakpoint-md-max){
    .HomePage h1, .HomePage h2 {
      padding-left:15px
    }
  }

</style>
