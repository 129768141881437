<script>
import { useRest } from 'src/lib/rest'
import { ref, onMounted, computed } from 'vue'
import { error } from 'src/lib/notify.js'
import { useQuasar } from 'quasar'

// Like lodash chunk
function chunk (arr, size) {
  const chunks = []
  for (let i = 0; i < arr.length; i += size) {
    chunks.push(arr.slice(i, i + size))
  }
  return chunks
}

export default {
  setup () {
    const rest = useRest()
    const slide = ref(0)
    const pros = ref([])
    const quasar = useQuasar()

    onMounted(async () => {
      try {
        const restPros = await rest.get('/rest/featured-pros')
        for (const pro of restPros) {
          pro.job = pro.jobs[Math.floor(Math.random() * pro.jobs.length)]
        }
        pros.value = restPros
      } catch (err) {
        error(err)
      }
    })

    const slides = computed(() => chunk(pros.value, quasar.screen.gt.sm ? 3 : 1))

    return {
      slides,
      slide,
      pros,
      quasar
    }
  }
}
</script>

<template>
  <q-carousel
    v-model="slide"
    transition-prev="slide-right"
    transition-next="slide-left"
    swipeable
    animated
    control-color="primary"
    padding
    arrows
    infinite
    height="auto"
    class="FeaturedJobotPros"
  >
    <q-carousel-slide v-for="(chunk, i) in slides" :key="i" :name="i" class="column no-wrap">
      <div class="row fit justify-start no-wrap" :class="{ 'q-col-gutter-xl': quasar.screen.gt.sm }">
        <div class="rounded-borders full-height col-md-4 col-12" v-for="(pro, j) in chunk" :key="j">
          <q-card class="my-card">
            <router-link :to="`/pro/${pro.slug}`">
              <q-img rounded :src="pro.image"
                     height="110px" width="110px"
                     class="q-mt-xl"
              />
            </router-link>
            <q-card-section>
              <router-link :to="`/pro/${pro.slug}`" class="q-link text-primary text-weight-bold block text-h6">
                {{ pro.name }}
              </router-link>
              <div class="text-subtitle2">
                {{ pro.title }}
              </div>
            </q-card-section>
            <q-card-section class="q-pa-lg text-left">
              <q-item-section>
                <q-item-label class="q-mb-md">
                  <span class=" text-weight-medium text-subtitle1 block">Accepting Applications:</span><span class="text-black-8 text-weight-bold text-subtitle1 block">{{ pro.jobcount }} jobs</span>
                </q-item-label>
                <q-item-label v-if="pro.job">
                  <span class="text-weight-medium text-subtitle1 block">Featured job:</span>
                  <router-link :to="'/' + pro.job.url.split('/').slice(3).join('/').replace(/\/$/, '')"
                               class="q-link text-weight-medium text-primary text-weight-bold text-subtitle1 block"
                  >
                    {{ pro.job.title }}
                  </router-link>
                </q-item-label>
              </q-item-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </q-carousel-slide>
  </q-carousel>
</template>
<style lang="scss" scoped>
  .FeaturedJobotPros {
    .q-img {
      border-radius: 100% !important;
    }
  }
</style>
