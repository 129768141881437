<script>
import { useLocalStorage } from 'src/lib/storage.js'
import { t } from 'src/lib/i18n.js'
import { useStore } from 'stores/api'
import { ref, computed, onMounted } from 'vue'
import { Notify } from 'quasar'

export default {
  async preFetch () {
    const store = useStore()
    console.log('Getting home')
    await store.dispatch('api/get', '/rest/home')
  },
  setup () {
    const localStorage = useLocalStorage()
    const store = useStore()

    const recent = ref([])
    const searchLink = (query) => {
      return {
        path: '/search',
        query: {
          q: query,
          l: lastSearch.value?.l ?? null
        }
      }
    }
    const clear = () => {
      Notify.create({
        icon: 'fas fa-triangle-exclamation',
        message: t('favoriteSearches.confirmClear'),
        position: 'center',
        progress: true,
        actions: [
          {
            label: t('message.clear'),
            color: 'negative',
            handler: () => {
              delete localStorage.recentSearches
              recent.value = []
            }
          },
          {
            label: t('message.dismiss')
          }
        ]
      })
    }
    onMounted(() => {
      const recentSearches = localStorage.recentSearches
      recent.value = Object.keys(Object.fromEntries((recentSearches || []).map(search => [search.q, null])))
    })
    const home = computed(() => store.state.api['/rest/home'])
    const popular = computed(() => home.value?.popularSearches?.map(x => x.query) ?? [])
    const lastSearch = computed(() => localStorage.jobSearch)

    return {
      recent,
      searchLink,
      clear,
      popular,
      lastSearch,
      t,
      home
    }
  }
}
</script>
<template>
  <q-card class="FavoriteSearches">
    <q-card-section>
      <div class="row justify-center q-col-gutter-md">
        <div class="col-md-6 col-12" v-if="recent.length">
          <div class="text-h5 text-center">
            {{ t('favoriteSearches.recentSearches') }}
          </div>
          <div class="q-gutter-sm q-mt-xs">
            <q-btn v-for="(search, i) in recent" :key="i"
                   no-caps
                   color="primary" outline
                   class="text-capitalize"
                   :to="searchLink(search)"
            >
              {{ search }}
            </q-btn>
            <div class="text-subtitle2 text-center">
              <q-btn size="xs" flat
                     color="primary" @click="clear()"
                     no-caps
              >
                {{ t('favoriteSearches.clearRecentSearches') }}
              </q-btn>
            </div>
          </div>
        </div>
        <div class="col-12 lt-md q-pa-sm">
          <q-separator inset />
        </div>
        <div class="col-md-6 col-12" :class="{'col-md-12': !recent.length}">
          <div class="text-h5 text-center">
            {{ t('favoriteSearches.popularSearches') }}
          </div>
          <div class="q-gutter-sm q-mt-xs">
            <q-btn v-for="(search, i) in popular" :key="i"
                   no-caps
                   color="primary" outline
                   class="text-capitalize"
                   :to="searchLink(search)"
            >
              {{ search }}
            </q-btn>
          </div>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>
